<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 养殖概况 -->
      <div class="content-item breeding-situation">
        <div class="content-title">
          <span>养殖概况</span>
        </div>
        <div class="content-charts">
          <!-- <div class="charts-item">
            <div class="item-val">
              {{ $.formatLargeNumber(infoData.aquacultureProduction || 0) }}
            </div>
            <div class="item-txt">养殖产量/吨</div>
          </div> -->
          <!-- <div class="charts-item">
            <div class="item-val">{{ infoData.breedingBase || 0 }}</div>
            <div class="item-txt">养殖基地/个</div>
          </div> -->
          <div class="charts-item">
            <div class="item-val">
              {{ $.formatLargeNumber(infoData.breedingArea || 0) }}
            </div>
            <div class="item-txt">养殖面积/亩</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              {{ $.formatLargeNumber(infoData.cultureCage || 0) }}
            </div>
            <div class="item-txt">深海网箱/个</div>
          </div>
        </div>
      </div>
      <!-- 饲料与水产品加工概况 -->
      <div class="content-item feed-processing-overview">
        <div class="content-title">
          <span>饲料与水产品加工概况</span>
        </div>
        <div class="content-charts">
          <div class="count-wrap">
            <div class="count-item">
              <div class="item-unit">&nbsp;</div>
              <div class="item-val">
                <CountFlop
                  :val="
                    $.formatMoney(infoData.foddersFeedOutputValue || 0, false)
                  "
                />
              </div>
              <div class="item-txt">饲料年产值/亿元</div>
            </div>
            <div class="count-item">
              <div class="item-unit">&nbsp;</div>
              <div class="item-val">
                <CountFlop
                  :val="
                    $.formatMoney(
                      infoData.foddersProcessingProductionCapacity || 0,
                      false
                    )
                  "
                />
              </div>
              <div class="item-txt">水产食品加工/万吨</div>
            </div>
          </div>
          <div class="bottom-part">
            <div class="bottom-item">
              <div class="item-val">
                {{ infoData.foddersFodderCompany || 0 }}
              </div>
              <div class="item-txt">饲料企业/家</div>
            </div>
            <div class="bottom-item">
              <div class="item-val">
                {{ infoData.foddersFeedAnnualProductionCapacity || 0 }}
              </div>
              <div class="item-txt">饲料年产能/万吨</div>
            </div>
            <div class="bottom-item">
              <div class="item-val">
                {{ infoData.foddersProcessingCompany || 0 }}
              </div>
              <div class="item-txt">食品加工企业/家</div>
            </div>
            <!-- <div class="bottom-item">
              <div class="item-val">
                {{ infoData.foddersProcessingProductionCapacity || 0 }}
              </div>
              <div class="item-txt">水产食品加工/万吨</div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 企业规模 -->
      <div class="content-item enterprise-scale">
        <div class="content-title">
          <span>企业规模</span>
        </div>
        <div class="content-charts">
          <div
            id="enterprise-scale-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="infoData.annualOutputValueTotal"
              :duration="2500"
              :decimals="2"
            ></CountTo>
            <i>亿元</i>
          </div>
          <div class="item-txt">年总产值</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="infoData.companyTotal"
              :duration="2500"
            ></CountTo>
            <i>家</i>
          </div>
          <div class="item-txt">分子公司</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="
                infoData.fisheryPopulationTotal === 1
                  ? 1.2
                  : infoData.fisheryPopulationTotal
              "
              :duration="2500"
              :decimals="1"
            ></CountTo>
            <i>万人</i>
          </div>
          <div class="item-txt">公司员工</div>
        </div>
      </div>

      <div class="middle-bottom-part" style="display:none">
        <!-- 种苗繁育概况 -->
        <div class="content-item seedling-breeding">
          <div class="content-title">
            <span>种苗繁育概况</span>
          </div>
          <div class="content-charts">
            <div
              id="seedling-breeding-chart1"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </div>
        <!-- 冷链概况 -->
        <div class="content-item cold-chain">
          <div class="content-title">
            <span>冷链概况</span>
          </div>
          <div class="content-charts">
            <div id="cold-chain-chart1" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 物流贸易概况 -->
      <div class="content-item logistics-trade" style="display:none">
        <div class="content-title">
          <span>物流贸易概况</span>
        </div>
        <div class="content-charts">
          <div class="charts-item">
            <div class="item-val">
              {{ infoData.logisticsInputOutMoney || 0 }}
            </div>
            <div class="item-txt">进出口总额/亿元</div>
          </div>
          <div class="charts-item">
            <div class="item-val">{{ infoData.logisticsInputOut || 0 }}</div>
            <div class="item-txt">进出口/万吨</div>
          </div>
          <div class="charts-item">
            <div class="item-val">{{ infoData.logisticsFishingPort }}</div>
            <div class="item-txt">渔港/个</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              {{ $.formatLargeNumber(infoData.logisticsShip || 0) }}
            </div>
            <div class="item-txt">船舶/艘</div>
          </div>
        </div>
      </div>

      <!-- 联农带农概况 -->
      <div class="content-item union-agriculture">
        <div class="content-title">
          <span>联农带农概况</span>
        </div>
        <div class="content-charts">
          <div class="count-wrap">
            <div class="count-item">
              <div class="item-val">
                <i>¥</i>
                <CountFlop
                  :val="(infoData.farmersGovernmentSubsidies || 0.0).toFixed(0)"
                />
                <i class="count-unit">万元</i>
              </div>
              <div class="item-txt">助农补贴</div>
            </div>
            <div class="count-item">
              <div class="item-val">
                <i></i>
                <CountFlop
                  :val="(infoData.farmersNumber || 0.0).toFixed(0)"
                />
                <i class="count-unit">户</i>
              </div>
              <div class="item-txt">带动农户</div>
            </div>
            <div class="count-item" v-if="false">
              <div class="item-val">
                <i>¥</i>
                <CountFlop
                  :val="(infoData.farmersLoanDiscount || 0.0).toFixed(0)"
                />
                <i class="count-unit">万元</i>
              </div>
              <div class="item-txt">农业建设</div>
            </div>
          </div>
          <div class="bottom-part" v-if="false">
            <div class="bottom-item">
              <div class="item-val">
                {{ $.formatLargeNumber(infoData.farmersNumber || 0) }}
                <i class="bottom-unit">户</i>
              </div>
              <div class="item-txt">带动农户</div>
            </div>
            <div class="bottom-item">
              <div class="item-val">
                {{ $.formatLargeNumber(infoData.farmersHouseAvgIncome || 0) }}
                <i class="bottom-unit">万元</i>
              </div>
              <div class="item-txt">户均年增收</div>
            </div>
            <div class="bottom-item">
              <div class="item-val">
                {{ $.formatMoney(infoData.farmersPeopleAvgIncome || 0, false) }}
                <i class="bottom-unit">万元</i>
              </div>
              <div class="item-txt">人均年收入</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 种苗繁育概况 -->
      <div class="content-item seedling-breeding">
        <div class="content-title">
          <span>种苗繁育概况</span>
        </div>
        <div class="content-charts">
          <div
            id="seedling-breeding-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 冷链概况 -->
      <div class="content-item cold-chain">
        <div class="content-title">
          <span>冷链概况</span>
        </div>
        <div class="content-charts">
          <div id="cold-chain-chart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <!-- 项目概况 -->
      <div class="content-item project-overview" style="display:none">
        <div class="content-title">
          <span>项目概况</span>
        </div>
        <div class="content-charts">
          <div
            id="project-overview-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <div class="two-small">
        <!-- 实景VR -->
        <div class="content-item virtual-reality">
          <div class="content-title">
            <span>实景VR</span>
          </div>
          <div class="content-charts" @click="toVr">
            <div class="video-box">
              <video
                src="../../../assets/video/vr-video.mp4"
                autoplay
                loop
                controls
                muted
              ></video>
            </div>
          </div>
        </div>
        <!-- 实时监控 -->
        <div class="content-item real-time-monitoring">
          <div class="content-title">
            <span>实时监控</span>
          </div>
          <!-- @click="dialogFormVisible = true" -->
          <div class="content-charts">
            <!-- <img src="../../../assets/img/large-screen/img-index02.png" alt /> -->
            <div class="video-box">
              <Video
                v-if="dialogFormVisible ? showVideo : videoItem && videoItem.ezopenUrl"
                :videoUrl="videoItem && videoItem.ezopenUrl"
                :token="videoItem && videoItem.accessToken"
                :videoCode="videoItem && videoItem.videoCode"
                :videoType="videoItem && videoItem.facilityCodeType"
              ></Video>
              <div class="video-mark" @click="dialogFormVisible = true"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
    <el-dialog
      title="实时监控"
      :width="'70%' || app.fontHandle(1600, 'px')"
      height="80vh"
      top="20vh"
      :visible.sync="dialogFormVisible"
    >
      <div class="content-charts">
        <div
          :class="[
            videoList && videoList.length == 0
              ? 'video-not-found'
              : 'video-box',
          ]"
          v-loading="!showVideo"
          element-loading-background="transparent"
        >
          <VideoPpopup
            v-if="showVideo"
            :videoUrl="videoItem && videoItem.ezopenUrl"
            :token="videoItem && videoItem.accessToken"
            :videoCode="videoItem && videoItem.videoCode"
            :videoType="videoItem && videoItem.facilityCodeType"
          ></VideoPpopup>
        </div>
        <div class="video-options">
          <div class="top-icon">
            <img
              src="../../../assets/img/large-screen/icon-arrow-up.png"
              alt
              @click="videoSwiper('down')"
            />
          </div>
          <div class="main-ls" ref="video_list">
            <div
              class="main-ls-context"
              :style="`margin-top: ${(curIdx * itemheight) / 15}px`"
            >
              <div
                v-for="(ele, i) in videoList"
                :key="i"
                :class="['ls-item', { active: ele.selected }]"
                @click="videoView(i)"
              >
                {{ ele.boxName.slice(0, 4) }}
              </div>
            </div>
          </div>
          <div class="bottom-icon">
            <img
              src="../../../assets/img/large-screen/icon-arrow-down.png"
              alt
              @click="videoSwiper('up')"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog } from 'element-ui'
Vue.use(Dialog)

import CountFlop from '../../../components/count-to-component.vue'
import containerTab from '../../../components/container-tab.vue'

import * as $echarts from 'echarts'
import CountTo from 'vue-count-to'
import Video from './video.vue'
import VideoPpopup from './video-popup.vue'

export default {
  name: 'zhanyu',
  components: {
    CountFlop,
    CountTo,
    containerTab,
    Video,
    VideoPpopup,
  },
  inject: ['app'],
  data() {
    return {
      itemheight: 0,
      curIdx: 0,
      dialogVRVisible: false, // vr弹窗
      infoData: {
        breedLineBarChart: [], //	养殖年产值与产量趋势
        breedPriceLineMoreChart: [], // 主要养殖品种价格走势
        breedValueList: [], // 养殖品种年产量
        breedVarietiesList: [], //
      },
      dialogFormVisible: false,
      // 种植繁育概况
      seedling: {
        idName: 'seedling-breeding-chart',
        legend: {
          data: ['种苗产量', '年产值'],
        },
        series: {
          lineData: {
            name: '种苗产量',
            data: [75, 70, 69, 72, 78],
          },
          barData: {
            name: '年产值',
            data: [32441, 32557, 32704, 28900, 27072],
          },
        },
        xAxis: {
          data: ['金鳗鱼', '三刀鱼', '红鱼', '海鲡鱼', '龙趸鱼'],
        },
      },
      // 冷链概况
      cold: {
        idName: 'cold-chain-chart',
        legend: {
          data: ['储存能力', '产值'],
        },
        series: {
          lineData: {
            name: '储存能力',
            data: [4900, 5100, 5500, 5300, 6000],
          },
          barData: {
            name: '产值',
            data: [57, 60, 63, 59, 67],
          },
        },
        xAxis: {
          data: ['2017年', '2018年', '2019年', '2020年', '2021年'],
        },
      },
      // 项目概况
      project: {
        idName: 'project-overview-chart',
        series: {
          data: [
            {
              value: 39600,
              name: '农业设备', //农业设备
            },

            {
              value: 100,
              name: '土地流转', //变动投资
            },
            {
              value: 9000, //产业整合
              name: '产业整合',
            },
            {
              value: 600, //科技研发
              name: '科技研发',
            },
            {
              value: 1700, //贷款贴息
              name: '贷款贴息',
            },
            {
              value: 15000, //其他
              name: '其他',
            },
          ],
        },
      },
      // 企业规模
      enterprise: {
        idName: 'enterprise-scale-chart',
        legend: {},
        tooltip: {
          formatter: '{b}:{c}万元',
        },
        yAxis: {
          data: [
            '湛江东鹏饲料有限公司',
            '徐闻县勒腾渔业有限公司',
            '湛江富洋水产有限公司',
            '湛江汇富海洋有限公司',
            '广东海威水产养殖有限公司',
            '徐闻县源海养殖有限公司',
            '湛江国联水产有限公司',
            '广东恒兴集团有限公司',
          ],
        },
        xAxis: {},
        series: {
          name: '注册资本',
          data: [4.8, 5, 6.1, 8.4, 10, 12, 13.3, 25],
        },
      },
      formLabelWidth: '120px',
      params: {},
      map: null,
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      videoList: null, // 视频列表
      videoUrl: null, // 视频路径
      videoItem: null,
      firstVideoUrl: null, // 视频路径
      showVideo: true, // 是否展示视频
      videoSwiperCount: 0, // 视频上下点击滑动计数
    }
  },
  methods: {
    toVr() {
      this.app.toVr()
      // window.open('https://www.expoon.com/e/6dek18z89md/panorama')
    },
    // 项目概况 圆饼图
    projectOverviewChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))

      let option = {
        backgroundColor: 'transparent',
        color: [
          '#5DE9C4',
          '#E654EB',
          '#5442FF',
          '#EBA447',
          '#4898FB',
          '#0CCCCE',
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}万元',
        },
        legend: {
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(10),
          orient: 'vertical',
          left: '60%',
          top: '20%',
          itemGap: fontHandle(24),
          textStyle: {
            color: '#FFFFFF',
            fontSize: fontHandle(16),
            rich: {
              a: {
                color: '#999',
                width: fontHandle(100),
              },
              b: {
                color: '#23c3ff',
                width: fontHandle(80),
                align: 'right',
              },
              c: {
                color: '#ffffff',
                width: fontHandle(100),
                align: 'left',
              },
            },
          },
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: 'rgba(0,0,0,0.2)',
              normal: {
                labelLine: {
                  // length: 6,
                  // length2: 10,
                  show: true,
                  color: '#ff0',
                },
              },
            },
            smooth: true,
            center: ['25%', '50%'],
            name: '',
            type: 'pie',
            radius: ['20%', '70%'],
            avoidLabelOverlap: true, //默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
            data: obj.series.data,
            label: {
              show: true,
              formatter: '\n{b}\n{d}%',
              position: 'outside',
              color: '#9BA7BF',
              fontSize: fontHandle(12),
            },
            labelLine: {
              normal: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: '#9BA7BF',
                },
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图
    seedlingBreedingChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          // right: 10,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(16),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(36, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: fontHandle(1),
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 4,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: 12,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
          {
            type: 'value',
            // name: '年产值',
            splitNumber: 4,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: fontHandle(1),
              },
            },
          },
        ],
        series: [
          {
            // name: '科苗产品',
            name: obj.series.lineData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: fontHandle(2),
              color: '#F06CFA',
            },
            data: obj.series.lineData.data,
            smooth: true,
            symbolSize: 0,
          },
          {
            // name: '年产值',
            name: obj.series.barData.name,
            type: 'bar',
            yAxisIndex: 1,
            data: obj.series.barData.data,
            barWidth: fontHandle(10),
            barGap: fontHandle(1),
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    enterpriseScaleChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          formatter: obj.tooltip.formatter,
        },
        color: obj.legend.data,
        legend: {
          show: false,
          bottom: fontHandle(10),
          textStyle: {
            color: '#fff',
            fontSize: fontHandle(16),
          },
          icon: 'circle',
          itemWidth: fontHandle(16),
          itemHeight: fontHandle(16),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(21, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            splitNumber: 6,
            nameTextStyle: {
              color: '#ffffff',
            },
            offset: 0,
            axisLabel: {
              color: '#838DA1',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#12fffe',
                width: 0,
                type: 'solid',
              },
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: obj.yAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.name,
            type: 'bar',
            data: obj.series.data,
            barWidth: fontHandle(10),
            barGap: 1,
            itemStyle: {
              normal: {
                barBorderRadius: fontHandle(10),
                color: new $echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 选择视频展示
    videoView(i) {
      this.showVideo = false
      this.videoList.forEach((re, j) => {
        re.selected = j == i ? (true, (this.videoItem = re)) : false
      })
      setTimeout(() => {
        this.showVideo = true
      }, 0)
    },
    // 视频列表上下滑动
    videoSwiper(btn) {
      this.itemheight = this.$refs.video_list.clientHeight // 列表元素高
      let curIdx = this.curIdx
      // 往上滑动
      if (btn == 'up') {
        curIdx--
      } else {
        curIdx++
      }
      console.log(this.videoList.length, this.curIdx, this.itemheight)
      if (
        this.videoList.length <= 15 ||
        !(curIdx >= -this.videoList.length + 15 && curIdx <= 0)
      ) {
        return
      }
      this.curIdx = curIdx
    },
  },
  mounted() {
    this.$axios('/zhanjiang-dataview/parkInformation/queryInfo').then((res) => {
      this.infoData = res.data
      // 企业规模
      this.enterprise.yAxis.data = []
      this.enterprise.series.data = []
      this.infoData.companyList.map((re, i) => {
        if (i < 9 || i > 19) return
        this.enterprise.yAxis.data.push(re.name)
        this.enterprise.series.data.push(re.value)
      })

      // 项目概况
      this.project.series.data = this.infoData.projectList
      // 种植繁育概况
      this.seedling.series.lineData.data =
        this.infoData.seedlingBreedingLineBarChart.lineChart
      this.seedling.series.barData.data =
        this.infoData.seedlingBreedingLineBarChart.barChart
      this.seedling.xAxis.data =
        this.infoData.seedlingBreedingLineBarChart.years.map((ele) => {
          if (ele.length > 5) {
            ele = ele.slice(0, 4) + '...'
          }
          return ele
        })
      // 冷链概况
      this.cold.series.lineData.data =
        this.infoData.coldChainLineBarChart.lineChart
      this.cold.series.barData.data =
        this.infoData.coldChainLineBarChart.barChart
      this.cold.xAxis.data = this.infoData.coldChainLineBarChart.years.map(
        (re) => re + '年'
      )

      this.enterpriseScaleChart(this.enterprise)
      this.projectOverviewChart(this.project)
      this.seedlingBreedingChart(this.seedling)
      this.seedlingBreedingChart(this.cold)
    })
  },
  created() {
    // 获取视频列表信息
    this.$axios({
      method: 'get',
      url: '/zhanjiang-dataview/viewDigitalPark/queryVideoList',
      data: null,
    })
      .then((res) => {
        if (res.code === '00000') {
          const data = res.data
          if (data instanceof Array) {
            data.forEach((re, i) => {
              re.selected = i == 0 ? (true, (this.videoItem = re)) : false
            })
          }
          this.videoList = data

          // 初始化视频
          this.videoView(0)
        }
      })
      .catch(() => {})
  },
}
</script>

<style lang="less" scoped>
@import './overview.less';
































</style>
